import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, TextInput, FlatList, Modal, ActivityIndicator } from 'react-native';
import { useTheme } from '../../Contexts/ThemeProvider';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import filterBlack from '../../Assets/Images/filterBlack.png';
import filterWhite from '../../Assets/Images/filterWhite.png';
import { LinearGradient } from 'expo-linear-gradient';
import { Width, color1 } from '../../Utility/Utility';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const RejectionReport = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const userId = useRef('')
    const [reportData, setReportData] = useState([])
    const [modalVisible, setModalVisible] = useState(false);
    const [date, setDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const [open, setOpen] = useState(false)
    const [dateType, setDateType] = useState('')
    // const [selectedSegment, setSelectedSegment] = useState('NSE')
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getData()
        getReport()
    }, [])

    const getData = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
        } catch (e) {
            // error reading value
        }
    }

    const getReport = (fromDate, toDate) => {
        setLoading(true)
        var fDate = fromDate ? moment(fromDate).format('YYYY-MM-DD') : ''
        var tDate = toDate ? moment(toDate).format('YYYY-MM-DD') : ''
        axios.get(`${BASE_URL}/trade-list?trade_status=REJECTED&from_date=${fDate}&to_date=${tDate}`)
            .then((res) => {
                setLoading(false)
                setReportData(res.data)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button style={{ color: colors.Secondary, backgroundColor: '#c97fc7', borderRadius: 12, height: 45, width: Width * 0.9, }} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Rejection Report</Text>
                    </View>
                    <View>
                        <View style={styles.headerIcon}>
                            <Pressable onPress={() => setModalVisible(true)}>
                                <Image source={dark ? filterWhite : filterBlack} style={styles.filterIcon} />
                            </Pressable>
                        </View>
                    </View>
                </View>
                <View style={{ marginTop: 30, marginLeft: 5, marginRight: 10 }}>
                    {/* TABLE HEADER */}
                    <View style={{ width: '100%', flexDirection: 'row', borderWidth: 1, borderColor: colors.Secondary }}>
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '15%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Time</Text>
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '15%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Script</Text>
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '15%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Type</Text>
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '15%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Lot</Text>
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Qty</Text>
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Rate</Text>
                        <Text style={{ fontSize: 10, fontWeight: '700', width: '20%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary }}>Reason</Text>
                    </View>
                    {/* TABLE CONTENT */}
                    {
                        !isLoading && reportData.length > 0 && <FlatList
                            data={reportData}
                            renderItem={({ item }) => <View style={{ flexDirection: 'row', width: '100%' }} key={new Date()}>
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '15%', paddingTop: 5, paddingBottom: 5, borderColor: colors.Secondary, borderBottomWidth: 1, color: colors.Secondary, borderLeftWidth: 1, borderColor: colors.Secondary, borderRightWidth: 1 }}>{moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</Text>
                                <Text style={{ fontSize: 11, fontWeight: '700', textAlign: 'center', width: '15%', paddingTop: 5, paddingBottom: 5, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderColor: colors.Secondary, borderRightWidth: 1 }}>{item?.script?.trading_symbol}</Text>
                                <Text style={{ fontSize: 11, fontWeight: '700', textAlign: 'center', width: '15%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderColor: colors.Secondary, borderRightWidth: 1 }}>{item.order_action}</Text>
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '15%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderColor: colors.Secondary, borderRightWidth: 1 }}>{item.lot}</Text>
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '10%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderColor: colors.Secondary, borderRightWidth: 1 }}>{item.quantity}</Text>
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '10%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderColor: colors.Secondary, borderRightWidth: 1 }}>{item.price}</Text>
                                <Text style={{ fontSize: 12, fontWeight: '700', textAlign: 'center', width: '20%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderRightWidth: 1 }}>{item.reject_reason}</Text>
                            </View>}
                            keyExtractor={item => item.id}
                        />
                    }
                </View>
                {
                    !isLoading && reportData.length == 0 &&
                    <View style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flex: 1 }}>
                        <Text style={{ color: colors.Secondary }}>No Rejection Found!</Text>
                    </View>
                }
                {isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
            </View>
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View >
                            <View style={{}}>
                                <Text style={{ color: colors.Secondary, fontSize: 18, fontWeight: 'bold', marginTop: 10 }}>Filter</Text>
                                {/* <View style={styles.segmentTabView}>
                                    <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'NSE' ? colors.Secondary : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { setSelectedSegment('NSE') }}>
                                        <Text style={[styles.tabText, { color: selectedSegment == 'NSE' ? colors.Primary : colors.Secondary }]}>NSE</Text>
                                    </Pressable>
                                    <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'MCX' ? colors.Secondary : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { setSelectedSegment('MCX') }}>
                                        <Text style={[styles.tabText, { color: selectedSegment == 'MCX' ? colors.Primary : colors.Secondary }]}>MCX</Text>
                                    </Pressable>
                                </View> */}
                                <Text style={styles.label}>From Date</Text>
                                <DatePicker selected={date} onChange={(date) => setDate(date)} customInput={<ExampleCustomInput />} />
                                <Text style={styles.label}>To Date</Text>
                                <DatePicker selected={enddate} onChange={(date) => setEndDate(date)} customInput={<ExampleCustomInput />} />
                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                    <Pressable style={styles.cancelButton} onPress={() => {
                                        setDate(new Date())
                                        setEndDate(new Date())
                                        setModalVisible(false)
                                        // setSelectedSegment('')
                                        getReport()
                                    }}>
                                        <Text style={styles.cancelText}>Reset</Text>
                                    </Pressable>
                                    <Pressable style={styles.cancelButton} onPress={() => setModalVisible(!modalVisible)}>
                                        <Text style={styles.cancelText}>Cancel</Text>
                                    </Pressable>
                                    <Pressable onPress={() => { setModalVisible(false), getReport(date, enddate) }}>
                                        <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#26D0CE', '#1A2980']} style={styles.continueButton}>
                                            <Text style={styles.continueText}>Continue</Text>
                                        </LinearGradient>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    )
}

export default RejectionReport;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
    headerTitle: {
        fontSize: 16,
    },
    filterIcon: {
        height: 25,
        width: 25,
        marginTop: 3,
        marginRight: 10
    },
    headerIcon: {
        flexDirection: 'row'
    },
    dateInput: {
        borderRadius: 12,
        width: Width * 0.9,
        height: 45,
        paddingLeft: 15,
        bottom: 6,
        marginTop: 10,
        justifyContent: 'center'
    },
    label: {
        color: color1,
        fontSize: 15,
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
        marginTop: 10
    },
    cancelButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
        borderWidth: 1
    },
    cancelText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    continueButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
    },
    continueText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    segmentTabView: {
        flexDirection: 'row',
        marginLeft: 22,
        marginRight: 22,
        marginTop: 10
    },
    segementTab: {
        width: '50%',
        alignItems: 'center',
        borderWidth: 1,
        padding: 3,
        borderRadius: 5,
        marginLeft: 5
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 50
    },
})